import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";
import 'simplebar/dist/simplebar.css';

import BScroll from '@better-scroll/core'
import ScrollBar from '@better-scroll/scroll-bar'
BScroll.use(ScrollBar);

export default class extends Controller {

    static targets = ['filteritems','veranstaltungen'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    initialize() {
        super.initialize();

        this.filteritemsTarget.querySelectorAll(".filteritem")[0].classList.add("!bg-black")
        this.filteritemsTarget.querySelectorAll(".filteritem")[0].classList.add("!text-white")
        this.filtertag(this.filteritemsTarget.querySelectorAll(".filteritem")[0].getAttribute("data-filter"))

        if(this.filteritemsTarget.getAttribute("data-initialized")==1) {
            this.switchWrapper.refresh()
        } else {

            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

            if(isTouchDevice) {
                this.switchWrapper = new BScroll(this.filteritemsTarget.querySelector(".scroller-wrapper"), {
                    scrollX: true,
                    scrollY: false, // Vertikaler Scroll deaktiviert
                    eventPassthrough: 'vertical', // Vertikales Scrollen wird nativ übergeben
                    wheel: true,
                    bounceTime: 400,
                    swipeBounceTime: 250,
                    click: true,
                    tap: true,
                    scrollbar: {
                        fade: true,
                        interactive: true
                    }
                })
            } else {

                this.switchWrapper = new BScroll(this.filteritemsTarget.querySelector(".scroller-wrapper"), {
                    scrollX: true,
                    wheel: true,
                    bounceTime: 400,
                    swipeBounceTime: 250,
                    click: true,
                    tap: true,
                    scrollbar: {
                        fade: true,
                        interactive: true
                    }
                })

            }

            this.filteritemsTarget.setAttribute("data-initialized", 1)

        }

    }

    filter(event) {

        const filteritems = this.filteritemsTarget.querySelectorAll(".filteritem");
        filteritems.forEach(filteritem => {
            filteritem.classList.remove("!bg-black")
            filteritem.classList.remove("!text-white")
        });
        event.target.classList.add("!bg-black")
        event.target.classList.add("!text-white")

        this.filtertag(event.target.getAttribute("data-filter"))

    }

    filtertag(tag) {

        const veranstaltungen = this.veranstaltungenTarget.querySelectorAll("a")
        veranstaltungen.forEach(veranstaltung => {

            if(veranstaltung.classList.contains(tag)) {
                veranstaltung.classList.remove("!hidden")
            } else {
                veranstaltung.classList.add("!hidden")
            }

        });
    }

    refresh() {
        this.switchWrapper.refresh()
    }


}