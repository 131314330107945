import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";
import BScroll from "@better-scroll/core";

export default class extends Controller {

    static targets = ['switch','tabs','tabsswitch'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    initialize() {
        super.initialize();



        if(this.tabsswitchTarget.getAttribute("data-initialized")==1) {
            this.switchWrapper.refresh()
        } else {

            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

            if(isTouchDevice) {
                this.switchWrapper = new BScroll(this.tabsswitchTarget, {
                    scrollX: true,
                    scrollY: false, // Vertikaler Scroll deaktiviert
                    eventPassthrough: 'vertical', // Vertikales Scrollen wird nativ übergeben
                    wheel: true,
                    bounceTime: 400,
                    swipeBounceTime: 250,
                    click: true,
                    tap: true,
                    scrollbar: {
                        fade: true,
                        interactive: true
                    }
                })
            } else {

                this.switchWrapper = new BScroll(this.tabsswitchTarget, {
                    scrollX: true,
                    wheel: true,
                    bounceTime: 400,
                    swipeBounceTime: 250,
                    click: true,
                    tap: true,
                    scrollbar: {
                        fade: true,
                        interactive: true
                    }
                })

            }
            this.tabsswitchTarget.setAttribute("data-initialized", 1)



        }

    }

    switch(event) {

        const switchitems = this.switchTarget.querySelectorAll(".switch-tab");
        switchitems.forEach(switchitem => {
            switchitem.classList.remove("active")
        });
        event.target.classList.add("active")

        const tabs = this.tabsTarget.querySelectorAll(".tab-content");
        tabs.forEach(tab => {
            if(tab.classList.contains("tab-"+event.target.getAttribute("data-tab"))) {
                tab.classList.remove("hidden")
            } else {
                tab.classList.add("hidden")
            }
        });

        const trigger = new CustomEvent("trigger-filterveranstaltungen");
        window.dispatchEvent(trigger);


    }



}