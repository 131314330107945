import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";
import 'simplebar/dist/simplebar.css';

import BScroll from '@better-scroll/core'
import ScrollBar from '@better-scroll/scroll-bar'
BScroll.use(ScrollBar);

export default class extends Controller {

    static targets = ['filteritems','veranstaltungen'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    initialize() {
        super.initialize();



        if(this.filteritemsTarget.getAttribute("data-initialized")==1) {
            if(this.switchWrapper) this.switchWrapper.refresh()
        } else {

            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

            if(isTouchDevice) {
                this.switchWrapper = new BScroll(this.filteritemsTarget.querySelector(".scroller-wrapper"), {
                    scrollX: true,
                    scrollY: false, // Vertikaler Scroll deaktiviert
                    eventPassthrough: 'vertical', // Vertikales Scrollen wird nativ übergeben
                    wheel: true,
                    bounceTime: 400,
                    swipeBounceTime: 250,
                    click: true,
                    tap: true,
                    scrollbar: {
                        fade: true,
                        interactive: true
                    }
                })
            } else {

                this.switchWrapper = new BScroll(this.filteritemsTarget.querySelector(".scroller-wrapper"), {
                    scrollX: true,
                    wheel: true,
                    bounceTime: 400,
                    swipeBounceTime: 250,
                    click: true,
                    tap: true,
                    scrollbar: {
                        fade: true,
                        interactive: true
                    }
                })

            }

            this.filteritemsTarget.setAttribute("data-initialized", 1)

        }

    }



    refresh() {
        this.switchWrapper.refresh()
    }


}