import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";
import 'simplebar/dist/simplebar.css';

import BScroll from '@better-scroll/core'
import ScrollBar from '@better-scroll/scroll-bar'
BScroll.use(ScrollBar);

export default class extends Controller {

    static targets = ['filter','buttonresult','buttonresultlabel'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    initialize() {
        super.initialize();

        this.buildButtonUrl()

    }

    filter(event) {

        event.target.closest(".calendar-filter").querySelector(".active").classList.remove("active")
        event.target.classList.add("active")

        const filters = this.filterTarget.querySelectorAll('.calendar-filter');
        const params = new URLSearchParams();

        filters.forEach(filter => {
            const filterName = filter.getAttribute('data-filter');
            const activeItem = filter.querySelector('.calendar-filter-item.active');

            if (activeItem) {
                const filterValue = activeItem.getAttribute('data-filter');
                params.append(filterName, filterValue);
            }
        });

        this.buildButtonUrl()


        const url = `/ajax/kalender_overlay_calc.twig?${params.toString()}`;


        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.buttonresultTarget.innerHTML = data;
                if(data==1) {
                    this.buttonresultlabelTarget.innerHTML = this.buttonresultTarget.getAttribute("data-singular")
                } else {
                    this.buttonresultlabelTarget.innerHTML = this.buttonresultTarget.getAttribute("data-plural")
                }

                if(data<1) {
                    this.buttonresultTarget.parentElement.classList.add("disabled")
                } else {
                    this.buttonresultTarget.parentElement.classList.remove("disabled")
                }

            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Daten:', error);
            });

    }

    buildButtonUrl() {

        const filters = this.filterTarget.querySelectorAll('.calendar-filter');
        const params = new URLSearchParams();

        filters.forEach(filter => {
            const filterName = filter.getAttribute('data-filter');
            const activeItem = filter.querySelector('.calendar-filter-item.active');

            if (activeItem) {
                const filterValue = activeItem.getAttribute('data-filter');
                params.append(filterName, filterValue);
            }
        });

        const currentUrl = new URL(window.location.href);
        const url = currentUrl.origin + currentUrl.pathname+ `?${params.toString()}`;

        this.buttonresultTarget.parentElement.setAttribute("href", url)
    }


}