import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['video','play'];

    static values = {
        active: Boolean,
    }

    connect() {



    }

    loadVideo() {

        let videoId = this.videoTarget.getAttribute("data-id");

        this.videoTarget.removeAttribute("data-action")
        this.videoTarget.classList.add("before:hidden")

        this.videoTarget.innerHTML = this.videoTarget.innerHTML + '<iframe class="absolute top-0 left-0 w-full h-full z-50" src="https://www.youtube.com/embed/'+videoId+'?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';

    }





}