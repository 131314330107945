import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";

export default class extends Controller {

    static targets = ['accordion','content','caret'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    toggle(event) {

        toggle(this.contentTarget)
        setTimeout(() => {
            this.contentTarget.closest(".accordion").scrollIntoView({ behavior: 'smooth' })
        }, 500)

        this.caretTarget.classList.toggle('!rotate-180')

    }


}