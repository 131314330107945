// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
import PerfectScrollbar from "perfect-scrollbar";

if (import.meta.hot) {
    import.meta.hot.accept(() => {
        //console.log("HMR")
    });
}

import '../css/main.css'

import './controllers'

import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import 'lazysizes';
//import 'perfect-scrollbar/css/perfect-scrollbar.css'
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

import BScroll from '@better-scroll/core'
import ScrollBar from '@better-scroll/scroll-bar'
BScroll.use(ScrollBar);

// You will need a ResizeObserver polyfill for browsers that don't support it! (iOS Safari, Edge, ...)
import ResizeObserver from 'resize-observer-polyfill';
import SimpleBar from "simplebar";
window.ResizeObserver = ResizeObserver;

document.addEventListener("DOMContentLoaded", function(){

    const phtml = document.querySelector("html")
    const pagebody = document.querySelector("body")
    const page = document.querySelector("#page")
    const hamburger = document.querySelector("#burger-menu");
    const mobileNavigationContainer = document.querySelector("#mobile-navigation-container");
    const darkOverlay = document.querySelector("#dark-overlay");
    const navbar = document.querySelector("#navbar");
    const desktopSubNavigation = document.querySelector("#desktop-sub-navigation");
    const desktopSubNavigationContainer = document.querySelector("#desktop-sub-navigation").querySelectorAll(".sub-navigation-container");
    const sections = document.querySelectorAll("section[id]");
    const ajaxOverlay = document.querySelector("#ajax-overlay");

    let globalTicketLinkWidth = 0;
    let anchorNavigationScrollWrapper = null;

    let scrolltimeout = null;

    setTimeout(() => {
        document.querySelector("html").classList.add("scroll-smooth")
    },1000);


    window.addEventListener('resize', function () {
        resize();
    });
    resize();


    function resize() {

        if (window.innerWidth > 1128) {
            // Close Mobile Menu while resizing
            if(pagebody.classList.contains("show-mobile-menu")) {
                hamburger.querySelector(".closer").classList.add("opacity-0")
                let bars = hamburger.querySelectorAll(".burger-bar");
                bars.forEach(function(bar) {
                    bar.classList.remove("w-0")
                });
                mobileNavigationContainer.classList.add("hidden");
                pagebody.classList.remove("show-mobile-menu");
                hamburger.removeEventListener('transitionend', hamburgerOut);
                unlockpage()
            }
        } else {
            // Close Desktop Menu while resizing
            if(pagebody.classList.contains("show-desktop-menu")) {
                pagebody.classList.remove("show-desktop-menu");
                unlockpage()
                darkOverlay.removeEventListener('click', closeDesktopNavigation);
                darkOverlay.classList.add("hidden");
                desktopSubNavigation.classList.add("hidden");
                desktopSubNavigationContainer.forEach((container, index) => {
                    container.classList.add("hidden")
                });

                const navbarActiveLink = document.querySelectorAll('.desktop-navbar-active-state');
                navbarActiveLink.forEach(link => {
                    link.classList.remove("desktop-navbar-active-state")
                });
            }
        }

        // Anchor Navigation
        if(document.querySelectorAll("#anchor-navigation").length) {
            if(document.querySelectorAll(".global-ticket-link").length) {
                globalTicketLinkWidth = 0;
                if (window.innerWidth > 1128) {
                    globalTicketLinkWidth = document.querySelector(".global-ticket-link").offsetWidth + 48
                }
            }

            anchorNavPadding()
        }

    }

    // Anchor Navigation
    if(document.querySelectorAll("#anchor-navigation").length) {
        initAncohorNavScroller()
        navHighlighter()
    }

    function initAncohorNavScroller() {
        if(document.getElementById("anchor-navigation").getAttribute("data-initialized")==1) {
            anchorNavigationScrollWrapper.refresh()
        } else {

            document.querySelector("html").classList.add("has-anchor-navigation")


            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

            if(isTouchDevice) {
                anchorNavigationScrollWrapper = new BScroll(document.getElementById("anchor-navigation").querySelector(".scroller-wrapper"), {
                    scrollX: true,
                    scrollY: false, // Vertikaler Scroll deaktiviert
                    eventPassthrough: 'vertical', // Vertikales Scrollen wird nativ übergeben
                    wheel: true,
                    bounceTime: 400,
                    swipeBounceTime: 250,
                    click: true,
                    tap: true,
                    scrollbar: {
                        fade: true,
                        interactive: true
                    }
                })
            } else {

                anchorNavigationScrollWrapper = new BScroll(document.getElementById("anchor-navigation").querySelector(".scroller-wrapper"), {
                    scrollX: true,
                    wheel: true,
                    bounceTime: 400,
                    swipeBounceTime: 250,
                    click: true,
                    tap: true,
                    scrollbar: {
                        fade: true,
                        interactive: true
                    }
                })

            }

            document.getElementById("anchor-navigation").setAttribute("data-initialized", 1)

        }
    }

    function anchorNavPadding() {
        clearTimeout(scrolltimeout)
        scrolltimeout = setTimeout(() => {
            if(document.querySelectorAll("#anchor-navigation").length) {
                if(pagebody.classList.contains("show-desktop-menu") || pagebody.classList.contains("show-mobile-menu")) return;

                //console.log(istSticky(document.getElementById("anchor-navigation")))

                if (istSticky(document.getElementById("anchor-navigation"))) {
                    document.querySelector("#anchor-navigation").style.paddingRight = globalTicketLinkWidth+"px";
                    pagebody.classList.add("anchor-navigation-sticky")
                } else {
                    document.querySelector("#anchor-navigation").style.paddingRight = "0px";
                    pagebody.classList.remove("anchor-navigation-sticky")
                }
                initAncohorNavScroller()
            }
        },50)
    }

    function easeInOutCubic(t) {
        return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    }

    function navHighlighter() {
        if(document.querySelectorAll("#anchor-navigation").length) {
            // Aktuelle Scroll-Position abrufen
            let scrollY = window.pageYOffset;
            const bodyMarginTop = parseInt(getComputedStyle(document.body).scrollMarginTop) || 0;
            scrollY += bodyMarginTop;

            // Durch alle Sektionen iterieren
            sections.forEach((section) => {
                let sectionHeight = section.offsetHeight + 60;


                if (window.innerWidth > 1128) {
                    sectionHeight = sectionHeight + 60;
                }

                const sectionTop = section.offsetTop - 50;
                const sectionId = section.getAttribute("id");

                // Navigationselement finden, das zur aktuellen Sektion gehört
                const navLink = document.querySelector(`#anchor-navigation a[href*="${sectionId}"]`);

                // Wenn das Element existiert, fügen wir die "active"-Klasse hinzu oder entfernen sie
                if (navLink) {
                    if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                        navLink.classList.add("!text-black");
                        if (document.getElementById("anchor-navigation").getAttribute("data-initialized") == true) {
                            anchorNavigationScrollWrapper.scrollToElement(navLink, 800, true, true, easeInOutCubic)
                        }
                    } else {
                        navLink.classList.remove("!text-black");
                    }
                }
            });

            if(scrollY<sections[0].offsetTop) {
                const navLinkFirst = document.querySelectorAll(`#anchor-navigation a`)[0];
                anchorNavigationScrollWrapper.scrollToElement(navLinkFirst, 800, true, true, easeInOutCubic)
            }

        }
    }

    window.addEventListener("scroll", anchorNavPadding);
    window.addEventListener("scroll", navHighlighter);

    function istSticky(element) {
        const elementPosition = element.getBoundingClientRect();
        const offset = parseInt(getComputedStyle(element).top) || 0;

        //console.log(parseInt(elementPosition.top),offset,parseInt(elementPosition.top) <= offset)

        // Prüfen, ob das Element den sticky Punkt erreicht hat
        return parseInt(elementPosition.top)+20 <= offset;
    }

    // Desktop Navigation

    const desktopNavigationImages = desktopSubNavigation.querySelectorAll('.lazy-start');
    desktopNavigationImages.forEach(img => {
        //img.classList.remove("lazy-start")
    });

    const desktopNavLinks = document.querySelectorAll('.desktop-main-navigation-action');

    desktopNavLinks.forEach(link => {
        link.addEventListener('click', handleDesktopNavClick);
    });

    desktopSubNavigation.querySelector(".navbar-closer").addEventListener('click', closeDesktopNavigation);

    const closerDesktopNavigationFromMobile = desktopSubNavigation.querySelectorAll(".icon-nav-closer");

    closerDesktopNavigationFromMobile.forEach(link => {
        link.addEventListener('click', closeDesktopNavigationFromMobile);
    });

    function handleDesktopNavClick(event) {
        event.preventDefault();
        const clickedLink = event.target;
        const parentLi = clickedLink.closest('li');
        const indexLink = clickedLink.getAttribute('data-index')-1;

        phtml.classList.remove("scroll-smooth")

        const navbarActiveLink = document.querySelectorAll('.desktop-navbar-active-state');
        navbarActiveLink.forEach(link => {
            link.classList.remove("desktop-navbar-active-state")
        });

        parentLi.classList.add("desktop-navbar-active-state")

        desktopSubNavigationContainer.forEach((container, index) => {
            if(index == indexLink) {
                container.classList.remove("hidden")
            } else {
                container.classList.add("hidden")
            }
        });

        if(pagebody.classList.contains("show-ajax-overlay")) {
            pagebody.classList.remove("show-ajax-overlay");
            darkOverlay.removeEventListener('click', closeAjaxOverlay);
            const overlaycloser = ajaxOverlay.querySelector('.navbar-closer')
            overlaycloser.removeEventListener('click', closeAjaxOverlay);
        }

        if(!pagebody.classList.contains("show-desktop-menu")) {
            lockpage();
            darkOverlay.classList.remove("hidden");
            darkOverlay.addEventListener('click', closeDesktopNavigation);
            desktopSubNavigation.classList.remove("hidden");
            setTimeout(() => {
                pagebody.classList.add("show-desktop-menu");

                desktopSubNavigation.addEventListener('transitionend', desktopSubNavigationIn);


            }, 10);
        } else {
            setTimeout(() => {
                desktopSubNavigation.querySelector(".simplebar-content-wrapper").scrollTop = 0
            }, 10)
        }

    }

    function desktopSubNavigationIn(event) {
        if (event.propertyName === 'clip-path') {
            desktopNavigationImages.forEach(img => {
                img.parentElement.classList.remove("opacity-0")
            });

            desktopSubNavigation.removeEventListener('transitionend', desktopSubNavigationIn);


        }
    }

    function closeDesktopNavigation(event) {
        pagebody.classList.remove("show-desktop-menu");
        unlockpage()
        darkOverlay.removeEventListener('click', closeDesktopNavigation);

        desktopSubNavigation.addEventListener('transitionend', hideDarkOverlay);

        const navbarActiveLink = document.querySelectorAll('.desktop-navbar-active-state');
        navbarActiveLink.forEach(link => {
            link.classList.remove("desktop-navbar-active-state")
        });
    }

    function closeDesktopNavigationFromMobile(event) {
        pagebody.classList.remove("show-desktop-menu");
        //unlockpage()
        darkOverlay.removeEventListener('click', closeDesktopNavigation);

        desktopSubNavigation.addEventListener('transitionend', hideDarkOverlay);

        const navbarActiveLink = document.querySelectorAll('.desktop-navbar-active-state');
        navbarActiveLink.forEach(link => {
            link.classList.remove("desktop-navbar-active-state")
        });
    }

    function hideDarkOverlay(event) {
        if (event.propertyName === 'clip-path') {
            darkOverlay.classList.add("hidden");
            desktopSubNavigation.removeEventListener('transitionend', hideDarkOverlay);
            ajaxOverlay.removeEventListener('transitionend', hideDarkOverlay);

            phtml.classList.add("scroll-smooth")
            unloadOverlay()
        }
    }

    // Mobile Navigation

    hamburger.addEventListener('click', () => {

        if(pagebody.classList.contains("show-mobile-menu")) {

            hamburger.querySelector(".closer").classList.add("opacity-0")
            hamburger.addEventListener('transitionend', hamburgerOut);

        } else {

            navbar.classList.add("h-screen-important")

            phtml.classList.remove("scroll-smooth")

            mobileNavigationContainer.classList.remove("hidden")

            setTimeout(() => {
                pagebody.classList.add("show-mobile-menu");
                setTimeout(() => {
                    let bars = hamburger.querySelectorAll(".burger-bar");
                    bars.forEach(function (bar) {
                        bar.classList.add("w-0")
                    });
                    bars[0].addEventListener('transitionend', hamburgerIn);
                    pagebody.classList.add("show-mobile-menu");

                    mobileNavigationContainer.addEventListener('transitionend', mobileNavigationIn);

                }, 10)

            }, 10)

        }


    });

    function mobileNavigationIn(event) {
        if (event.propertyName === 'clip-path') {
            navbar.querySelector(".scroll-container").classList.add("overflow-y-scroll")
            navbar.querySelector(".scroll-container").classList.add("scrollbar-hide")
            navbar.querySelector(".scroll-container").scrollTop = 0
            //page.classList.add("hidden")
            mobileNavigationContainer.removeEventListener('transitionend', mobileNavigationIn);
            lockpage()
        }
    }

    function hamburgerIn(event) {
        if (event.propertyName === 'width') {
            let bars = hamburger.querySelectorAll(".burger-bar");
            hamburger.querySelector(".closer").classList.remove("hidden")
            setTimeout(() => {
                hamburger.querySelector(".closer").classList.remove("opacity-0")
                bars[0].removeEventListener('transitionend', hamburgerIn);
            }, 10)
        }
    }

    function hamburgerOut(event) {
        if (event.propertyName === 'opacity') {
            let bars = hamburger.querySelectorAll(".burger-bar");
            bars.forEach(function(bar) {
                bar.classList.remove("w-0")
            });
            pagebody.classList.remove("show-mobile-menu");
            unlockpage()
            mobileNavigationContainer.addEventListener('transitionend', overlayout);
            hamburger.removeEventListener('transitionend', hamburgerOut);
            navbar.querySelector(".scroll-container").scrollTop = 0

        }
    }

    function overlayout(event) {
        if (event.propertyName === 'clip-path') {
            mobileNavigationContainer.classList.add("hidden");
            mobileNavigationContainer.removeEventListener('transitionend', overlayout);
            navbar.classList.remove("h-screen-important")


            phtml.classList.add("scroll-smooth")
        }
    }

    // Mobile Icon Links

    const mobileIconNavLinks = document.querySelectorAll('.mobile-icon-navigation');

    mobileIconNavLinks.forEach(link => {
        link.addEventListener('click', handleMobileIconNavClick);
    });

    function handleMobileIconNavClick(event) {
        event.preventDefault();
        const clickedLink = event.target;
        const parentLi = clickedLink.closest('li');
        const indexLink = clickedLink.getAttribute('data-index')-1;


        const navbarActiveLink = document.querySelectorAll('.desktop-navbar-active-state');
        navbarActiveLink.forEach(link => {
            link.classList.remove("desktop-navbar-active-state")
        });

        parentLi.classList.add("desktop-navbar-active-state")

        desktopSubNavigationContainer.forEach((container, index) => {
            if(index == indexLink) {
                container.classList.remove("hidden")
            } else {
                container.classList.add("hidden")
            }
        });

        if(!pagebody.classList.contains("show-desktop-menu")) {
            lockpage();
            darkOverlay.classList.remove("hidden");
            darkOverlay.addEventListener('click', closeDesktopNavigation);
            desktopSubNavigation.classList.remove("hidden");
            setTimeout(() => {
                pagebody.classList.add("show-desktop-menu");

                desktopSubNavigation.addEventListener('transitionend', desktopSubNavigationIn);


            }, 10);
        } else {
            setTimeout(() => {
                desktopSubNavigation.querySelector(".simplebar-content-wrapper").scrollTop = 0
            }, 10)
        }

    }

    function conditionalPreventScroll(e) {
        if (!e.target.closest('#navbar') && !e.target.closest('#desktop-sub-navigation') && !e.target.closest('#ajax-overlay')) {
            // Scrollen verhindern, wenn das Ziel außerhalb der erlaubten Bereiche ist
            e.preventDefault();
        }
    }

    function lockpage() {

        window.addEventListener('wheel', conditionalPreventScroll, { passive: false });
        window.addEventListener('touchmove', conditionalPreventScroll, { passive: false });



        /*

        //disablePageScroll();

        document.querySelector("html").classList.remove("scroll-smooth")
        document.querySelector("html").classList.add("locked")

        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        //document.body.style.overflowY = 'hidden'
        document.body.style.width = '100%'

        */



    }

    function unlockpage() {

        window.removeEventListener('wheel', conditionalPreventScroll, { passive: false });
        window.removeEventListener('touchmove', conditionalPreventScroll, { passive: false });

/*
        return;

        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        //document.body.style.overflowY = ''
        document.body.style.width = ''

        window.scrollTo(0, parseInt(scrollY || '0') * -1);

        setTimeout(() => {
            document.querySelector("html").classList.add("scroll-smooth")
            document.querySelector("html").classList.remove("locked")
        }, 20)

        //enablePageScroll();






         */
    }

    // Lazysizes

    let lazys = document.querySelectorAll(".lazy-start");
    lazys.forEach(function(entry) {
        entry.classList.add("lazyload")
    });

    // Image Titlebar


    document.addEventListener('click', function(event) {

        var active = true;
        const titlebar = event.target.closest('.image-titlebar');

        if (titlebar) {
            if(!titlebar.classList.contains('active')) {
                // Die gefundene .image-titlebar bekommt die Klasse 'active'
                titlebar.classList.add('active');
                titlebar.classList.remove('overflow-hidden');
                titlebar.classList.remove('w-10');
                titlebar.classList.remove('h-10');
                active = false;
            }
        } else {
            // Wenn außerhalb einer .image-titlebar geklickt wird, alle 'active' Klassen entfernen
            document.querySelectorAll('.image-titlebar.active').forEach(activeTitlebar => {
                activeTitlebar.classList.remove('active');
                activeTitlebar.querySelector(".image-titlebar-content").addEventListener('transitionend', activeTitleBarCloser);

            });
        }

        // Prüfen, ob auf das .image-titlebar-closer Kind geklickt wurde
        if (event.target.classList.contains('image-titlebar-closer')) {
            const activeTitlebar = event.target.closest('.image-titlebar');
            if (activeTitlebar && activeTitlebar.classList.contains('active') && active) {
                // Wenn die zugehörige titlebar aktiv ist, 'active' Klasse entfernen
                activeTitlebar.classList.remove('active');
                activeTitlebar.querySelector(".image-titlebar-content").addEventListener('transitionend', activeTitleBarCloser);
            }
        }


    });

    function activeTitleBarCloser() {
        this.parentNode.classList.add('overflow-hidden');
        this.parentNode.classList.add('w-10');
        this.parentNode.classList.add('h-10');
        this.removeEventListener('transitionend', activeTitleBarCloser);
    }

    // Content Slider

    let contentScroller = document.querySelectorAll(".content-scroller");
    contentScroller.forEach(function(scroller) {

        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

        if(isTouchDevice) {
            new BScroll(scroller, {
                scrollX: true,
                scrollY: false, // Vertikaler Scroll deaktiviert
                eventPassthrough: 'vertical', // Vertikales Scrollen wird nativ übergeben
                wheel: true,
                bounceTime: 400,
                swipeBounceTime: 250,
                click: true,
                tap: true,
                scrollbar: {
                    fade: true,
                    interactive: true
                }
            })
        } else {

            new BScroll(scroller, {
                scrollX: true,
                wheel: true,
                bounceTime: 400,
                swipeBounceTime: 250,
                click: true,
                tap: true,
                scrollbar: {
                    fade: true,
                    interactive: true
                }
            })

        }
    });

    // Teaser Angebote

    document.querySelectorAll('.toggle-info').forEach(element => {
        element.addEventListener('click', (event) => {
            event.preventDefault()
            const parentElement = element.parentElement;
            const nextElement = parentElement.nextElementSibling;
            if (nextElement) {
                nextElement.classList.remove('opacity-0');
            }
            element.classList.add("toggled")
        });
    });

    document.addEventListener('click', (event) => {
        if (!event.target.closest('.toggle-info') && !event.target.classList.contains("toggle-info") && !event.target.closest('.angebot')) {
            document.querySelectorAll('.toggle-info.toggled').forEach(element => {
                element.classList.remove('toggled');
                const parentElement = element.parentElement;
                const nextElement = parentElement.nextElementSibling;
                if (nextElement) {
                    nextElement.classList.add('opacity-0');
                }
            });
        }
    });

    // Ajax Overlay

    // Funktion zum Laden des AJAX-Inhalts
    function loadOverlayAjax(url, large=false, background='#FFF', color='has-text-black') {

        let overlaytimeout =  setTimeout(() => {
            document.querySelector('.overlay-loader').classList.remove("hidden")
        }, 500)

        ajaxOverlay.style.backgroundColor = background
        ajaxOverlay.classList.add("has-"+color)

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then(html => {
                clearTimeout(overlaytimeout);
                document.querySelector('.overlay-loader').classList.add("hidden")

                let overlayContainer = document.querySelector('#overlay-container');

                setTimeout(() => {
                    //overlayContainer.innerHTML = html;

                    loadHtmlWithScripts(document.querySelector('#overlay-container'), html)

                    init_freeform()

                    if (url.includes('?')) {
                        // Nur den Query-String extrahieren
                        const queryString = url.split('?')[1];
                        // Parameter auslesen
                        const params = new URLSearchParams(queryString);
                        const raumParam = params.get('raum');

                        if (raumParam) {
                            // Select-Feld finden
                            const selectField = document.getElementById('form-input-raum');
                            if (selectField) {
                                // Option vorselektieren
                                selectField.value = raumParam;
                            }
                        }
                    }

                    overlayContainer.classList.remove("hidden")
                    setTimeout(() => {
                        overlayContainer.classList.remove("opacity-0")

                        let lazys = document.querySelectorAll(".lazy-start");
                        lazys.forEach(function(entry) {
                            entry.classList.add("lazyload")
                        });

                    },10)
                }, 50)


            })
            .catch(error => {
                console.error('Error loading content:', error);
            });
    }

    // Funktion zum Laden des AJAX-Inhalts
    function loadOverlay(id) {

        const overlayContentHTML = document.querySelector('#'+id).innerHTML

        let overlayContainer = document.querySelector('#overlay-container');

        setTimeout(() => {
            overlayContainer.innerHTML = overlayContentHTML;
            overlayContainer.classList.remove("opacity-0")
            overlayContainer.classList.remove("hidden")



        }, 10)
    }

    function unloadOverlay() {
        let overlayContainer = document.querySelector('#overlay-container');
        overlayContainer.innerHTML = "";
        overlayContainer.classList.add("opacity-0")
        overlayContainer.classList.add("hidden")
        ajaxOverlay.classList.remove("lg:w-overlay-large");
        ajaxOverlay.classList.remove("lg:max-w-overlay-large");
        ajaxOverlay.classList.remove("has-text-white");
        ajaxOverlay.classList.remove("has-text-black");
        ajaxOverlay.classList.remove("lg:w-overlay-large");
        ajaxOverlay.style.backgroundColor = "#FFF"
    }

    // Beispiel-Aufruf der Funktion, z.B. beim Seitenladen oder einem bestimmten Event
    //loadOverlay('/ajax/ausstellung-besuch?ausstellung=389');

    document.querySelectorAll('.open-overlay').forEach(element => {
        element.addEventListener('click', (event) => {
            event.preventDefault()

            if(!pagebody.classList.contains("show-ajax-overlay")) {

                //const url = event.target.getAttribute("data-href")
                //loadOverlayAjax(url);

                phtml.classList.remove("scroll-smooth")

                if(event.target.classList.contains("open-overlay-ajax")) {

                    if(event.target.classList.contains("open-overlay-ajax-large")) {
                        ajaxOverlay.classList.add("lg:w-overlay-large");
                        ajaxOverlay.classList.add("lg:max-w-overlay-large");
                    }

                    const url = event.target.getAttribute("data-href")
                    const background = event.target.getAttribute("data-background")
                    const color = event.target.getAttribute("data-color")
                    loadOverlayAjax(url,false,background,color);

                    lockpage();
                    darkOverlay.classList.remove("hidden");
                    darkOverlay.addEventListener('click', closeAjaxOverlay);
                    ajaxOverlay.classList.remove("hidden");
                    setTimeout(() => {
                        pagebody.classList.add("show-ajax-overlay");

                        ajaxOverlay.addEventListener('transitionend',ajaxOverlayIn);


                    }, 10);

                    const overlaycloser = ajaxOverlay.querySelector('.navbar-closer')
                    overlaycloser.addEventListener('click', closeAjaxOverlay);
                } else {
                    const id = event.target.getAttribute("data-id")
                    loadOverlay(id);

                    lockpage();
                    darkOverlay.classList.remove("hidden");
                    darkOverlay.addEventListener('click', closeAjaxOverlay);
                    ajaxOverlay.classList.remove("hidden");
                    setTimeout(() => {
                        pagebody.classList.add("show-ajax-overlay");

                        ajaxOverlay.addEventListener('transitionend',ajaxOverlayIn);


                    }, 10);

                    const overlaycloser = ajaxOverlay.querySelector('.navbar-closer')
                    overlaycloser.addEventListener('click', closeAjaxOverlay);
                }



            }

        })
    })

    function ajaxOverlayIn(event) {
        if (event.propertyName === 'clip-path') {
            ajaxOverlay.removeEventListener('transitionend', ajaxOverlayIn);
        }
    }

    function closeAjaxOverlay(event) {
        pagebody.classList.remove("show-ajax-overlay");
        unlockpage()
        darkOverlay.removeEventListener('click', closeAjaxOverlay);

        ajaxOverlay.addEventListener('transitionend', hideDarkOverlay);

        const overlaycloser = ajaxOverlay.querySelector('.navbar-closer')
        overlaycloser.removeEventListener('click', closeAjaxOverlay);

    }


    // Form
    function init_freeform() {

        const form = document.querySelector('form[data-freeform]');
        if (form) {


            form.addEventListener('freeform-ajax-success', function (event) {

                let success_message = document.querySelector(".freeform-form-success").cloneNode(true);
                form.innerHTML = success_message.outerHTML
            });
        }

    }

});

function loadHtmlWithScripts(container, html) {
    // HTML in den Container einfügen
    container.innerHTML = html;

    // Alle eingefügten <script>-Tags finden
    const scripts = container.querySelectorAll('script');

    scripts.forEach(script => {
        // Neues <script>-Element erstellen
        const newScript = document.createElement('script');
        newScript.type = script.type || 'text/javascript';

        // Wenn das Script eine src-Attribut hat (externes Script)
        if (script.src) {
            newScript.src = script.src;
        } else {
            // Inline-Script (Code direkt im Script-Tag)
            newScript.textContent = script.textContent;
        }

        // Das neue Script ins DOM einfügen, damit es ausgeführt wird
        container.appendChild(newScript);

        // Optional: Entfernen, um doppelte Ausführung zu vermeiden
        script.remove();
    });
}